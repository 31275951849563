* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
}

@media (min-width: 567px) {
  html {
    font-size: 22px;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 26px;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 29px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 36px;
  }
}
@media (min-width: 2560px) {
  html {
    font-size: 48px;
  }
}
@media (min-width: 3640px) {
  html {
    font-size: 75px;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: radial-gradient(#0c0a20, #0b091b, #000000);
}

.birthday {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: 'Pacifico', cursive;
  font-size: 2.1rem;
  color: white;
  text-align: center;
  z-index: 3;
  display: block;
}

@media (min-width: 768px) {
  .birthday {
    font-size: 2.2rem;
  }
}

.emoji {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: 'Pacifico', cursive;
  font-size: 2.1rem;
  color: white;
  text-align: center;
  z-index: 3;
  display: block;
}

@media (min-width: 768px) {
  .birthday {
    font-size: 2.2rem;
  }
}
